<template>
  <div id="app1">
    <div  >
       <div class="header P_2" @click="goPrev">
        <img src="../assets/icon30.png" alt="">
        <span>企业经营透明指数</span>
      </div>
    </div>
    <div class="box">
      <img src="../assets/jingyingtoumingbanner@2x.png" alt="">
      <div class="abso" style="padding:1rem 1.5rem;width:100%">
        <div class="txt1">企业经营透明指数</div>
        <div class="txt2">{{Number(scoreItem.transparent_operation_score).toFixed(1) }} <span>分</span></div>
        <div class="model1">
          <div class="title">指数解读</div>
          <div class="subTitle">企业经营透明指数是基于以下纬度生成</div>
          <div class="btns">
            <div class="btn2" @click="goDetail(4,'财报造假风险')" style="background-color:#FFEFF5;color: #B00A32;">财报造假</div>
            <div class="btn2" @click="goDetail(4,'财报造假风险')" style="background-color:#FFEFF5;color: #B00A32;" >虚假交易</div>
            <div class="btn2" @click="goDetail(4,'财报造假风险')" style="background-color:#FFEFF5;color: #B00A32;">空壳交易</div>
          </div>
          <div>
            <div class="title2">本次信用测评为：<span style="color:#FF1950">{{content2}}</span> </div>
            <div class="txt">{{content4}}</div>
          </div>
          <div class="title2" style="padding-bottom:1.3rem;">经营透明指数发展趋势为：<span style="color:#0063FF">平稳</span> </div>
        </div>
        <!-- <div class="model2">
          <div>
            <div class="title">企业经营透明指数</div>
            <div class="txt">{{content2}}</div>
          </div>
             <div  id="main" style="width: 25rem; height:10rem;"></div>
        </div> -->
        <div class="model3">
          <div>
            <div class="title">评价说明</div>
            <div class="companyTitle title" style="margin-top:0;padding:1rem 0">
              <div class="dot" style="background-color:#FF1E54"></div>
              <div style="margin-left:0.5rem; font-weight: 600;">{{content3}}</div> 
            </div>
            <div class="txt">{{content1}}</div>
          </div>
        </div>
        <div style="height:5rem"></div>
      </div>
    </div>
    <div class="fixBtn"> 
      <div class="btn" @click="goPrev" style="width:100%;">返回 力度分</div>  
      <!-- <div class="btn MR_2" @click="goPrev">上一页</div>  
      <div class="btn"  @click="goNext">下一页</div>   -->
      <!-- <div class="btn" style="border-top-right-radius: 5rem;border-bottom-right-radius: 5rem;">详细信息</div>   -->
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import {mapState,} from "vuex"
export default {
  mounted(){
    let {
      credit_quality_score,
      operational_capability_level,
      transparent_operation_level,
      viability_score,
      transparent_operation_score,
      ecological_chain_health_score,
      competition_score,
      operational_capability_score} = this.scoreItem
    //  let arr = [credit_quality_score,viability_score,transparent_operation_score,ecological_chain_health_score,competition_score,operational_capability_score].map(Number)
    //  this.initEacharts(arr)
     this.getContent(transparent_operation_level)
  },
  data(){
    return {
      content1:"", //评价
      content2:"", //能力
      content3:"", //评价标题
      content4:"",
    }
  },
   computed:{
    ...mapState({
      scoreItem:  (state) => state.scoreItem,
    }),
  },
  methods:{
    goDetail(type,content){
      this.$router.push({path: "/getLmxDesc",query:{
        type,
        content,
      }})
    },
    getContent(val){
      console.log(val)
      switch (val){
        case 'A':
            this.content1 = "诚立天下，该企业对外信息及各类交易经营记录真实程度高达xx%，点个赞。"  
            this.content4 = "企业信息披露真实程度极高，财务及交易状况真实良好，点个赞。"  
            this.content2 = "极好"
            this.content3 = "最强王者，完美无瑕。"  
            break;
        case 'B':
            this.content1 = "重信守法，精诚合作，该企业对外信息及各类交易经营记录真实程度很高，不要错过哦～"  
            this.content4 = "企业信息披露真实程度很高，没有异常违规记录，值得信赖。"  
            this.content2 = "优秀"
            this.content3 = "璀璨钻石，精益求精。"  
            break;
        case 'C':
            this.content1 = "诚实守信，经商有道，该企业对外信息及各类交易经营记录真实程度良好。"  
            this.content4 = "企业信息披露真实程度良好，不断完善企业财务及交易记录，精益求精。"  
            this.content2 = "良好"
            this.content3 = "华贵铂金，赤诚相待。"  
            break;
        case 'D':
            this.content1 = "该企业对外信息及各类交易经营记录真实程度还有加强的空间，建议多观察一段时间。"  
            this.content4 = "企业信息披露真实程度不错，仍需注意经营及交易数据记录，未来可期。"
            this.content2 = "中等"  
            this.content3 = "荣耀黄金，信守不渝。"  
          break;
         case 'E':
            this.content1 = "企业信息真实度处于青铜水平，还有很大发展空间～"  
            this.content4 = "企业正在不断提升财务及经营实力，努力提高信息真实度。" 
            this.content2 = "较差" 
            this.content3 = "坚韧黄铜，去伪存真。"  
          break;
        default:
        }
    },
    // 雷达图
    initEacharts(arr) {
      var myChart = echarts.init(document.getElementById('main'))
      var option;
      option = {
      radar: {
        indicator: [
            { name: '信用质量评分', max: 1000,   },
            { name: '生存能力评分', max: 1000,},
            { name: '企业透明指数', max: 1000,color:"#FF1950", },
            { name: '生态链健康指数', max: 1000 },
            { name: '同业竞争评分', max: 1000 },
            { name: '经营能力评分', max: 1000, }
        ],
        center: ['57%', '45%'],
        radius: 25,
      },
      series: [{
        type: 'radar',
        data: [
          {
            value: arr,
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                {
                  color: 'rgba(255,25,80, 0.1)',
                  offset: 0 
                },
                {
                  color: 'rgba(255,25,80, 0.9)',
                  offset: 1
                }
              ])
            }
          }
      ]
      }]
    };
    myChart.setOption(option); 
    },
    goPrev(){
      this.$router.push("/reportDetail")
    },
    goNext(){
      this.$router.push("/health")
    }
  }
}
</script>
<style lang="scss" scoped>
#app1{
  background:#fff;
}
.list{
  margin-top:2rem;
  display: flex;
  justify-content:space-around;
  align-items:flex-end;
  .item{
     
    .bar{
      width: 1rem;
      // height: 2rem;
       
      border-radius: 1rem;
      margin:0 auto;
    }
    .txt{
      width:2rem;
      margin-top:0.5rem;
    }
  }
}
 .companyTitle{
  display: flex;
  align-items: center;
 }
</style>